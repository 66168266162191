main.main {
  padding: 0;
}

section {
  width: 100vh;
  height: 100vh;
}

.decoration {
  &:after {
    content: "";
    display: block;
    width: 320px;
    height: 86px;
    background-image: url(../images/line.svg);
    background-size: 320px;
    background-position: center center;
    background-repeat: no-repeat;
    margin: -20px auto 0;
  }
}

.arrow {
  display: block;
  width: 20px;
  height: 22px;
  background-image: url(../images/arrow-down.svg);
  background-size: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 10px auto 10px;
  animation: pulse 3s infinite;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.8s ease-out;
  background-color: rgba(0, 0, 0, 0.97);
  background-image: url(../images/background-radial.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  will-change: transform;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;

  &.ready {
    opacity: 0;
  }

  .loader {
    position: absolute !important;
    top: 40%;
    left: 50%;
    background-image: url(../images/circle.svg);
    background-repeat: no-repeat;
    background-size: contain;
    transform: translate(-50%, -50%);
    width: 90%;

    @include media-breakpoint-up(md) {
      width: 300px;
      height: 300px;
      top: 50%;
    }

    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 85%;
      height: 60%;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url(../images/logo-beaujolais.svg);
      background-size: 100%;
      transform: translate(-50%, -50%);
    }

    .get-ready {
      position: absolute;
      right: 0;
      top: 125%;
      text-transform: uppercase;
      color: #ffffff;
      font-family: $font-roboto;
      font-weight: 200;
      font-size: 14px;
      width: 100%;
      text-align: center;

      @include media-breakpoint-up(md) {
        font-size: 16px;
        right: 25%;
        top: 125%;
        text-align: left;
      }
      
      @include media-breakpoint-up(lg) {
        top: 50%;
        right: -120%;
      }

      &:before {
        content: "";
        display: inline-block;
        width: 30px;
        height: 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url(../images/headphones.svg);
        background-size: 100%;
        margin: 0 20px -10px 0;

        @include media-breakpoint-up(md) {
          width: 40px;
          height: 40px;
        }
      }
    }

    svg {
      -webkit-mask-image: url(../images/circle.svg);
      -webkit-mask-size: contain;
    }

    .progressbar-text {
      top: 80% !important;
      color: $color-red !important;
    }
  }

  .disclaimer {
    background-color: $color-red;
    transition: opacity 0.6s ease-out;
    opacity: 0;
    color: #ffffff;
    padding: 40px 0;
    max-width: 90%;

    @include media-breakpoint-up(md) {
      padding: 40px 80px;
    }

    p {
      font-family: $font-roboto;
      font-weight: 300;
      font-size: 16px;
      line-height: 1.2;
      margin: 0;
      text-align: center;
      padding: 10px;

      @include media-breakpoint-up(md) {
        max-width: 400px;
      }
    }

    &.ready {
      opacity: 1;
    }
    
    .logo {
      display: block;
      width: 280px;
      height: 110px;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url(../images/logo-beaujolais.svg);
      background-size: 100%;
      margin: -10px auto 10px;
    }

    a {
      display: block;
      border: none;
      background-color: #000000;
      color: #ffffff;
      font-weight: 400;
      font-family: $font-roboto;
      text-transform: uppercase;
      padding: 20px;
      margin: 40px 0px -40px 0px;
      cursor: pointer;
      transition: all 0.2s ease-out;

      @include media-breakpoint-up(md) {
        margin: 40px -80px -40px -80px;
      }

      &:hover, &:active, &:focus {
        color: rgba(256, 256, 256, 0.8);
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: translateY(-15px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-15px);
  }
}

.only-mobile {
  @include media-breakpoint-up(md) {
      display: none;
  }
}

.hide-mobile {
  display: none;
  
  @include media-breakpoint-up(md) {
      display: block;
  }
}