section.main {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
  color: #ffffff;
  z-index: 5;

  .d-flex {
    height: 100%;
    text-align: center;
  }

  .logo {
    width: 75%;
    height: auto;
    min-height: 150px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../images/logo-beaujolais.svg);
    background-size: contain;
    margin: auto;
    
    @include media-breakpoint-up(sm) {
      min-height: 250px;
    }

    @include media-breakpoint-up(md) {
      min-height: 240px;
      width: 50%;
      padding-top: 9%;
      padding-bottom: 9%;
    }
  }

  .proposed {
    margin-bottom: 20px;
  }

  .inter {
    margin: auto;
  }

  p {
    margin: 1em 0;
    font-family: $font-roboto;
    font-weight: 400;
    font-size: 14px;
  }

  .deeper {
    position: relative;
    cursor: pointer;
    margin-bottom: 50px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 75px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 50px;
    }

    &:hover {
      top: 2px;
    }

    &:focus, &:active {
      top: 5px;
    }

    p {
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      font-family: $font-roboto;
      font-weight: 300;
      font-size: 20px;
    }
  }
}

section.slide {
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 5;

  .plyr {
    width: 100%;
    height: 100%;
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .background-video {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      transform: translate3d(0,0,0);
    }

    .plyr__video-wrapper {
      position: static;
    }

    video {
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }

  .content {
    width: 100%;
    height: 65%;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate3d(0,0,0);

    sup {
      top: -0.7em;
      font-size: 50%;
    }
  }

  .image {
    position: absolute;
    height: 100%;
    width: auto;
    top: 50%;
    left: 80%;
    transform: translate(-100%, -50%);

    @include media-breakpoint-up(md) {
      left: 70%;
    }

    @include media-breakpoint-up(lg) {
      left: 50%;
    }
  }

  &:nth-of-type(odd) {

    .image {
      transform: translate(-60%, -50%);

      @include media-breakpoint-up(md) {
        transform: translate(-100%, -50%);
      }

      @include media-breakpoint-up(lg) {
        transform: translate(0%, -50%);
      }
    }

    .content .article-button {
      transform: translateX(0px);

      @include media-breakpoint-up(md) {
        transform: translateX(-106px);
      }

      @include media-breakpoint-up(lg) {
        transform: translateX(107px);
      }
    }
  }

  .wrapper {
    position: relative;
    z-index: 10;

    h2 {
      color: #ffffff;
      font-family: $font-roboto;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 30px;

      @include media-breakpoint-up(md) {
        font-size: 40px;
      }
    }
  }

  .content .article-button {
    width: 240px;
    height: 70px;
    background-color: $color-dark;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transform: translateX(0px);
    padding-left: 60px;
    padding-right: 20px;
    text-align: left;

    @include media-breakpoint-up(md) {
      width: 300px;
      height: 90px;
      padding-left: 70px;
      padding-right: 30px;
      transform: translateX(-106px);
    }

    a {
      text-transform: uppercase;
      font-family: $font-roboto;
      font-weight: 200;
      font-size: 16px;
      color: #ffffff;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: calc(50% - 10px);
        left: -40px;
        display: block;
        width: 16px;
        height: 20px;
        background-image: url(../images/arrow-left.svg);
        background-size: 16px;
        background-position: center;
        background-repeat: no-repeat;
      }

      &:hover, &:focus, &:active {
        &:before {
          animation: pulse-left 0.7s infinite;
        }
      }

      @keyframes pulse-left {
        0% {
          transform: translateX(0px);
        }
        50% {
          transform: translateX(-15px);
        }
        100% {
          transform: translateX(0px);
        }
      }
    }

    &:nth-of-type(even) {
      padding-left: 20px;
      padding-right: 70px;

      @include media-breakpoint-up(md) {
        padding-left: 30px;
        padding-right: 80px;
      }

      a {
        &:before {
          left: auto;
          right: -40px;
          background-image: url(../images/arrow-right.svg);
        }

        &:hover, &:focus, &:active {
          &:before {
            animation: pulse-right 0.7s infinite;
          }
        }

        @keyframes pulse-right {
          0% {
            transform: translateX(0px);
          }
          50% {
            transform: translateX(15px);
          }
          100% {
            transform: translateX(0px);
          }
        }
      }
    }
  }

  .show-player {
    cursor: pointer;
  }

  article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 15;
    transform: translateX(-200px);
    transition: all 0.2s ease-out;
    visibility: hidden;
    opacity: 0;

    &.show {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
    }

    .close {
      position: absolute;
      top: 20px;
      left: 20px;
      display: inline-block;
      width: 52px;
      height: 52px;
      background-image: url(../images/button-close.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 52px;
      margin-right: 5px;
      float: none;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.6s ease-out;
      z-index: 11;

      @include media-breakpoint-up(md) {
          top: 30px;
          left: 30px;
      }

      &:hover, &:active, &:focus {
        opacity: 1;
      }
    }

    .slide-title {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      font-family: $font-roboto;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 22px;
      z-index: 10;
      background-color: white;
      width: 100%;
      padding-left: 90px;
      height: 90px;
      align-items: center;

      @include media-breakpoint-up(md) {
        top: 0;
        left: 96px;
        background-color: transparent;
        padding: 0;
        width: auto;
        height: 110px;
      }

      .wrapper {

      }
    }

    .article-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: 110px;
      background-color: #ffffff;
      overflow: auto;

      @include media-breakpoint-up(md) {
        overflow: hidden;
        padding-top: 180px;
      }

      @include media-breakpoint-up(lg) {
        width: calc(100% - 290px);
      }

      .title {

        @include media-breakpoint-up(md) {
          position: absolute;
          top: 110px;
          left: 35%;
          width: 65%;
          z-index: 10;
        }
      }

      .container {

        @include media-breakpoint-up(md) {
          padding-left: 90px;
          padding-right: 90px;
        }
      }

      .img-wrap {
        position: relative;
      }

      .img-caption {
        position: absolute;
        top: 30%;
        right: 2%;
        width: 55%;

        p {
          font-size: 18px;
          font-family: $font-roboto;
          font-weight: bold;
          line-height: 1.4;
          color: #ffffff;
          text-align: center;

          @include media-breakpoint-up(md) {
            font-size: 18px;
          }

          @include media-breakpoint-up(lg) {
            font-size: 22px;
          }
        }
      }

      .text-column {
        margin-top: 20px;
        margin-bottom: 40px;

        @include media-breakpoint-up(md) {
          margin-top: 80px;
          margin-bottom: 0;
        }
      }

      h1 {
        color: $color-dark;
        font-family: $font-roboto;
        font-weight: 700;
        font-size: 30px;
        line-height: 28px;
        text-transform: uppercase;
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-up(md) {
          padding-left: 0;
          padding-right: 0;
          font-size: 50px;
          line-height: 45px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 80px;
          line-height: 75px;
        }
      }

      h2 {
        color: $color-red;
        font-family: $font-roboto-article;
        font-weight: 200;
        font-size: 24px;
        margin-bottom: 1em;
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-up(md) {
          padding-left: 0;
          padding-right: 0;
        }
      }

      p {
        font-family: $font-roboto-article;
        font-size: 16px;
        line-height: 30px;
      }

      .slick-next {
        right: 0;

        &:after {
          background-image: url(../images/arrow-right-red.svg);
        }
      }

      .slick-prev {
        left: 0;

        &:before {
          background-image: url(../images/arrow-left-red.svg);
        }
      }

      .slick-prev {
        top: calc(100% + 28px);
        z-index: 10;
        font-size: 16px;
        line-height: 16px;
        font-family: $font-roboto;
        font-weight: 200;
        color: $color-red;
        text-transform: uppercase;
        width: 120px;

        &:before {
          content: "";
          display: inline-block;
          width: 16px;
          height: 12px;
          background-size: 16px;
          background-repeat: no-repeat;
          background-position: center;
          margin-right: 20px;

          @include media-breakpoint-up(md) {
            margin-right: 10px;
          }

          @include media-breakpoint-up(lg) {
            margin-right: 20px;
          }
        }
      }

      .slick-next {
        top: calc(100% + 28px);
        z-index: 10;
        font-size: 16px;
        line-height: 16px;
        font-family: $font-roboto;
        font-weight: 200;
        color: $color-red;
        text-transform: uppercase;
        width: 200px;

        @include media-breakpoint-up(md) {
          width: auto;
        }

        @include media-breakpoint-up(lg) {
          width: 200px;
        }

        &:before {
          content: "";
        }

        &:after {
          content: "";
          display: inline-block;
          width: 16px;
          height: 12px;
          background-size: 16px;
          background-repeat: no-repeat;
          background-position: center;
          margin-left: 20px;

          @include media-breakpoint-up(md) {
            margin-left: 10px;
          }

          @include media-breakpoint-up(lg) {
            margin-left: 20px;
          }
        }
      }

      .slick-disabled {
        opacity: 0;
        cursor: auto;

        &:before, &:after {
          opacity: 0;
        }
      }

      .slick-dots {
        bottom: -40px;

        li {
          width: 10px;
        }

        button {
          &:before {
            font-size: 30px;
            width: 15px;
          }
        }
      }
    }

    .video {
      display: none;

      @include media-breakpoint-up(md) {
        position: fixed;
        top: 0;
        left: 0;
        right: auto;
        width: 230px;
        height: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @include media-breakpoint-up(lg) {
        top: 0;
        left: auto;
        right: 0;
        width: 290px;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .arrow {
    @include media-breakpoint-down(md) {
        display: none;
    }
  }
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

.play {
  display: inline-block;
  width: 86px;
  height: 86px;
  background-image: url(../images/button-play.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 86px;
  margin-bottom: -6px;
  overflow: hidden;
  position: relative;

  &:hover, &:active, &:focus {
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 5px;
      height: 5px;
      background: rgba(255, 255, 255, .2);
      opacity: 0;
      border-radius: 100%;
      transform: scale(1, 1) translate(-50%);
      transform-origin: 50% 50%;
      animation: ripple 1s ease-out;
    }
  }

  &:active {
    top: 1px;
    left: 1px;
  }
}

.map {
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 52px;
  height: 52px;
  background-image: url(../images/button-map.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 52px;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    top: 30px;
    left: 30px;
  }
}

.controls {
  position: absolute;
  top: 10px;
  left: 10px;

  @include media-breakpoint-up(md) {
    top: 30px;
    left: 30px;
  }

  .controls-button {
    display: inline-block;
    width: 52px;
    height: 52px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 52px;
    margin-right: 5px;
    float: none;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.6s ease-out;

    &:hover, &:active, &:focus {
      opacity: 1;
    }
  }

  .close {
    background-image: url(../images/button-close.svg);
  }

  .pause {
    background-image: url(../images/button-pause.svg);

    &.active {
      background-image: url(../images/button-paused.svg);
    }
  }

  .sound {
    background-image: url(../images/button-sound.svg);

    &.active {
      background-image: url(../images/button-mute.svg);
    }
  }

  .fullscreen {
    background-image: url(../images/button-fullscreen.svg);

    &.active {
      background-image: url(../images/button-fullscreen.svg);
    }
  }
}

.side-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  padding-top: 20px;
  z-index: 100;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  @include media-breakpoint-up(md) {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    width: 230px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
    padding-top: 20px;

    &:before {
      display: none;
    }
  }

  &.mobile-hide {
    @include media-breakpoint-down(md) {
        visibility: hidden;
    }
  }

  .mobile-close {
    position: absolute;
    top: 10px;
    right: 5px;
    display: inline-block;
    width: 52px;
    height: 52px;
    background-image: url(../images/button-close.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 52px;
    margin-right: 5px;
    float: none;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.6s ease-out;
    
    @include media-breakpoint-up(md) {
        display: none;
    }
  }

  .logo {
    position: relative;
    display: block;
    width: 100%;
    height: 60px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../images/logo-beaujolais.svg);
    background-size: contain;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      width: 210px;
      height: 80px;
    }
  }

  .navigation {
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 160px);
    text-align: center;
    align-items: center;
    margin-bottom: 40px;
    justify-content: center;

    .cls-3 {
      transform: scale(2);
      transform-origin: center;
    }

    @include media-breakpoint-up(md) {
      height: calc(100% - 120px);

      .cls-3 {
        transform: scale(1);
        transform-origin: center;
      }
    }

    svg {
      height: 100%;
    }

    .link {
      cursor: pointer;
    }
  }
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition: opacity 0.3s ease-out;
  will-change: opacity;
  opacity: 0;
  visibility: hidden;

  .plyr__video-embed {
    min-height: 100%;
  }

  .slide-title {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      position: absolute;
      top: 30px;
      right: 30px;
      font-family: $font-roboto;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 52px;
      z-index: 10;
      color: #ffffff;
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }

  .article-button {
    display: none;

    @include media-breakpoint-up(md) {
      display: flex;
      position: absolute;
      top: 90px;
      right: 30px;
      padding-left: 30px;
      padding-right: 80px;

      a {
        &:before {
          left: auto;
          right: -40px;
          background-image: url(../images/arrow-right.svg);
        }

        &:hover, &:focus, &:active {
          &:before {
            animation: pulse-right 0.7s infinite;
          }
        }

        @keyframes pulse-right {
          0% {
            transform: translateX(0px);
          }
          50% {
            transform: translateX(15px);
          }
          100% {
            transform: translateX(0px);
          }
        }
      }
    }
  }

  .thumbnail {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      position: absolute;
      top: 90px;
      right: 330px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 90px;
      height: 90px;
    }
  }
}

.arrow {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;

  @include media-breakpoint-up(md) {
    bottom: 45px;
  }

  @include media-breakpoint-up(lg) {
    bottom: 20px;
  }

  &:focus, &:active {
    bottom: 16px;
  }
}

.map-overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transform: translateY(-100%);
  transition: all 0.2s ease-out;
  visibility: hidden;
  opacity: 0;

  &.show {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);

    &.inner {
      .back {
        @include media-breakpoint-down(md) {
          display: inline-block;
        }
      }

      .close {
        left: 75px;
      }
    }
  }

  .close {
    position: absolute;
    top: 10px;
    left: 10px;
    display: inline-block;
    width: 52px;
    height: 52px;
    background-image: url(../images/button-close.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 52px;
    margin-right: 5px;
    float: none;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.6s ease-out;
    z-index: 10;

    &:hover, &:active, &:focus {
      opacity: 1;
    }

    @include media-breakpoint-up(md) {
      top: 30px;
      left: 30px;
    }
  }

  .back {
    display: none;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 52px;
    height: 52px;
    background-image: url(../images/button-back.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 52px;
    margin-right: 5px;
    float: none;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.6s ease-out;
    z-index: 10;

    &:hover, &:active, &:focus {
      opacity: 1;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .logo {
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: 70px;
    z-index: 5;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../images/logo-beaujolais.svg);
    background-size: contain;
    margin-top: 10px;
    
    @include media-breakpoint-up(md) {
      top: 0;
      right: auto;
      left: 0;
      width: 35%;
      height: 100px;
      background-position: 50% 80%;
      margin-top: 0px;
    }
    
    @include media-breakpoint-up(lg) {
      margin-top: 40px;
      background-position: center center;
    }
  }

  .selector {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../images/background-map.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    text-align: center;

    @include media-breakpoint-up(md) {
      width: 35%;
    }

    .svg-wrapper {
      margin-top: 120px;
      height: 65%;

      @include media-breakpoint-up(md) {
        height: 100%;
        width: 200%;
        margin-top: 200px;
        transform: translateX(-65%)
      }

      @include media-breakpoint-up(lg) {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        transform: translateX(0%);
      }
    }
    
    .title {
      cursor: pointer;
    }

    .map-france {
      position: absolute;
      bottom: 20px;
      right: 20px;
      width: 85px;
      height: 85px;
      background-image: url(../images/map-france.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;

      @include media-breakpoint-up(md) {
        bottom: 40px;
        right: 40px;
        width: 125px;
        height: 125px;
      }
    }
  }

  .region {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    color: #ffffff;
    padding: 75px 20px 20px;
    transition: opacity 0.6s ease-out;
    opacity: 0;
    overflow: auto;

    &.active {
      opacity: 1;
      visibility: visible;
    }
    
    @include media-breakpoint-up(md) {
      padding: 50px 100px;
    }

    @include media-breakpoint-down(md) {
      .container {
        padding: 0;
      }
    }
  }

  .content {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.6s ease-out;
    z-index: 5;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    @include media-breakpoint-up(md) {
      display: block;
      position: absolute;
      top: 0;
      left: 35%;
      width: 65%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.9);
      visibility: visible;
      opacity: 1;
    }

    .slick-dots {
      bottom: -40px;

      li button:before {
        color: #fff;
      }
    }

    h2 {
      margin-top: 0.5em;
      margin-bottom: 0.25em;
      font-family: $font-roboto;
      font-weight: 700;
      font-size: 30px;
      line-height: 0.95;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        font-size: 50px;
        line-height: 45px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 80px;
        line-height: 75px;
      }
    }
  }
}

.article-button {
  width: 300px;
  height: 90px;
  background-color: $color-dark;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 70px;
  padding-right: 30px;
  text-align: left;

  a {
    text-transform: uppercase;
    font-family: $font-roboto;
    font-weight: 200;
    font-size: 16px;
    color: #ffffff;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: calc(50% - 10px);
      left: -40px;
      display: block;
      width: 16px;
      height: 20px;
      background-image: url(../images/arrow-left.svg);
      background-size: 16px;
      background-position: center;
      background-repeat: no-repeat;
    }

    &:hover, &:focus, &:active {
      &:before {
        animation: pulse-left 0.7s infinite;
      }
    }

    @keyframes pulse-left {
      0% {
        transform: translateX(0px);
      }
      50% {
        transform: translateX(-15px);
      }
      100% {
        transform: translateX(0px);
      }
    }
  }
}

.mobile-nav {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 52px;
  height: 52px;
  background-image: url(../images/button-menu.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 52px;
  cursor: pointer;

  @include media-breakpoint-up(md) {
      display: none !important;
  }
}