footer {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.footer-menu {
  padding: 0 0 10px 10px;

  @include media-breakpoint-up(md) {
    padding: 0 0 15px 15px;
  }

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: space-around;

    @include media-breakpoint-up(md) {
      display: inline-flex;
    }

    li {
      list-style: none;
    }
  }

  .menu-item {
    font-family: $font-roboto;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      margin-right: 25px;
    }

    a {
      color: #ffffff;
    }
  }
}