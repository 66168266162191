// Grid settings
$main-sm-columns:        12;
$sidebar-sm-columns:     4;

// Fonts
$font-roboto:            'Roboto Condensed', sans-serif;
$font-roboto-article:    'Roboto', sans-serif;

// Colors
$color-red:              #d11944;
$color-dark:             #1c1a1a;

// General
$border-radius:          0;

// Slider
$slick-font-family:      Arial;
$slick-dot-color:        $color-dark;
$slick-dot-color-active: $color-red;
$slick-dot-size:         30px;
$slick-loader-path:      "../images/";